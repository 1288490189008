import React from 'react';
import { graphql } from 'gatsby';

import { BlogLayout, PostCard, SubscribeBanner } from '../../components/blog';

const AuthorPage: React.FC<{ data: any }> = ({ data }) => {
  const author = data && data.ghostAuthor;
  const posts = data.allGhostPost.edges.map(p => p.node);

  const postList = posts.map(post => <PostCard key={post.id} {...post} />);
  postList.splice(3, 0, <SubscribeBanner key="blog-main-subscribe" />);

  return (
    <BlogLayout title={author.name} seoPrefix="Fidel API in Focus - ">
      <div className="centered">
        <h2 className="post-collection-title">
          <span style={{ fontWeight: 500 }}>Posts by</span> {author.name}
        </h2>
        <p className="secondary-text">{author.bio}</p>
      </div>
      <ul className="blog-post-list" data-testid="blog-post-list">
        {postList}
      </ul>
    </BlogLayout>
  );
};

export const pageQuery = graphql`
  query GhostAuthorQuery($slug: String!) {
    ghostAuthor(slug: { eq: $slug }) {
      slug
      name
      bio
      profile_image
    }
    allGhostPost(
      sort: { order: DESC, fields: [published_at] }
      filter: { authors: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      edges {
        node {
          id
          slug
          title
          custom_excerpt
          feature_image
          html

          primary_tag {
            name
            slug
          }
        }
      }
    }
  }
`;

export default AuthorPage;
